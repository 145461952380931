var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Настройки ")])],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-card-title',[_vm._v(" Тарифы (аренда) ")]),_c('validation-observer',{ref:"settings"},[_c('form',[_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Стоимость 1 TH/s (USD)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Стоимость 1 TH/s (USD)")]),_c('b-form-input',{attrs:{"placeholder":"35","type":"number"},model:{value:(_vm.form.thsp),callback:function ($$v) {_vm.$set(_vm.form, "thsp", $$v)},expression:"form.thsp"}})]}}])})],1),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Потребление Ватт за 1 TH/s","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Потребление Ватт за 1 TH/s")]),_c('b-form-input',{attrs:{"placeholder":"35","type":"number"},model:{value:(_vm.form.wpt),callback:function ($$v) {_vm.$set(_vm.form, "wpt", $$v)},expression:"form.wpt"}})]}}])})],1),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Стоимость 1 кВ/ч","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Стоимость 1 кВ/ч (USD)")]),_c('b-form-input',{attrs:{"placeholder":"2.5","type":"number"},model:{value:(_vm.form.kvth),callback:function ($$v) {_vm.$set(_vm.form, "kvth", $$v)},expression:"form.kvth"}})]}}])})],1),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Стоимость Э/Э на 1 TH/s"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Стоимость Э/Э на 1 TH/s (USD)")]),_c('b-form-input',{attrs:{"value":_vm.energy,"type":"number","disabled":""}})]}}])})],1),_c('b-card-title',{staticStyle:{"margin-top":"1.53rem"}},[_vm._v(" Партнерская программа ")]),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Прибыль партнера за контракт реферала периодом 12-23 месяца (%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Прибыль партнера за контракт реферала периодом 12-23 месяца (%)")]),_c('b-form-input',{attrs:{"placeholder":"","type":"number"},model:{value:(_vm.form.ref_invitation_percent_12_23),callback:function ($$v) {_vm.$set(_vm.form, "ref_invitation_percent_12_23", $$v)},expression:"form.ref_invitation_percent_12_23"}})]}}])})],1),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Прибыль партнера за контракт реферала периодом 24-35 месяцев (%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Прибыль партнера за контракт реферала периодом 24-35 месяцев (%)")]),_c('b-form-input',{attrs:{"placeholder":"","type":"number"},model:{value:(_vm.form.ref_invitation_percent_24_35),callback:function ($$v) {_vm.$set(_vm.form, "ref_invitation_percent_24_35", $$v)},expression:"form.ref_invitation_percent_24_35"}})]}}])})],1),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Прибыль партнера за контракт реферала периодом 36 месяцев (%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Прибыль партнера за контракт реферала периодом 36 месяцев (%)")]),_c('b-form-input',{attrs:{"placeholder":"","type":"number"},model:{value:(_vm.form.ref_invitation_percent_36),callback:function ($$v) {_vm.$set(_vm.form, "ref_invitation_percent_36", $$v)},expression:"form.ref_invitation_percent_36"}})]}}])})],1),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Прибыль партнера за покупку оборудования рефералом (%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Прибыль партнера за покупку оборудования рефералом (%)")]),_c('b-form-input',{attrs:{"placeholder":"","type":"number"},model:{value:(_vm.form.ref_invitation_percent_m),callback:function ($$v) {_vm.$set(_vm.form, "ref_invitation_percent_m", $$v)},expression:"form.ref_invitation_percent_m"}})]}}])})],1),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Бонус рефералу на первую аренду мощности (%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Бонус рефералу на первую аренду мощности (%)")]),_c('b-form-input',{attrs:{"placeholder":"","type":"number"},model:{value:(_vm.form.ref_invitee_percent),callback:function ($$v) {_vm.$set(_vm.form, "ref_invitee_percent", $$v)},expression:"form.ref_invitee_percent"}})]}}])})],1),_c('b-form-group',{staticClass:"form-group-with-label"},[_c('validation-provider',{attrs:{"name":"Скидка рефералу на первую покупку оборудования (%)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('span',{class:{
                                    'text-muted' : errors.length > 0 ? false : true,
                                    'font-weight-bolder': errors.length > 0 ? true : false,
                                    'text-danger' : errors.length > 0 ? true : false
                                },staticStyle:{"font-size":"12px"}},[_vm._v("Скидка рефералу на первую покупку оборудования (%)")]),_c('b-form-input',{attrs:{"placeholder":"","type":"number"},model:{value:(_vm.form.ref_invitee_percent_m),callback:function ($$v) {_vm.$set(_vm.form, "ref_invitee_percent_m", $$v)},expression:"form.ref_invitee_percent_m"}})]}}])})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1)],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-card-title',[_vm._v(" Интеграции ")]),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" AmoCRM "),(_vm.integrations.amocrm)?_c('b-badge',{staticClass:"mr-1 ml-auto",attrs:{"pill":""}},[_vm._v(" работает ")]):_c('b-badge',{staticClass:"mr-1 ml-auto",attrs:{"pill":"","variant":"danger"}},[_vm._v(" не работает ")])],1),(!_vm.integrations.amocrm)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.connectWithAmoCRM}},[_vm._v(" Подключить AmoCRM ")]):_c('b-button',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                                        loader: true,
                                        ok: function (dialog) { return _vm.deleteAmoCRM(dialog); },
                                        message: {
                                            title: 'Удаление интеграции AmoCRM',
                                            body: 'Вы действительно хотите удалить интеграцию?',
                                        },
                                    }),expression:"{\n                                        loader: true,\n                                        ok: dialog => deleteAmoCRM(dialog),\n                                        message: {\n                                            title: 'Удаление интеграции AmoCRM',\n                                            body: 'Вы действительно хотите удалить интеграцию?',\n                                        },\n                                    }"}],attrs:{"variant":"danger"}},[_vm._v(" Удалить AmoCRM ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }