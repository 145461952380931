<template>

    <b-card no-body>
        <b-card-header>
            <b-card-title>
                Настройки
            </b-card-title>
        </b-card-header>
        
        <b-card-body>
            <b-row>
                <b-col md="6">
                    <b-card-title>
                       Тарифы (аренда)
                    </b-card-title>
                     <validation-observer ref="settings">
                        <form>
                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Стоимость 1 TH/s (USD)"
                                        rules="required"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Стоимость 1 TH/s (USD)</span>                             
                                    <b-form-input  placeholder="35" type="number" v-model="form.thsp" />

                                </validation-provider>
                             </b-form-group>
                            
                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Потребление Ватт за 1 TH/s"
                                        rules="required"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Потребление Ватт за 1 TH/s</span>                             
                                    <b-form-input  placeholder="35" type="number" v-model="form.wpt" />

                                </validation-provider>
                             </b-form-group>
                            
                             <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Стоимость 1 кВ/ч"
                                        rules="required"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Стоимость 1 кВ/ч (USD)</span>                             
                                    <b-form-input  placeholder="2.5" type="number" v-model="form.kvth" />

                                </validation-provider>
                             </b-form-group>
                            
                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Стоимость Э/Э на 1 TH/s"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Стоимость Э/Э на 1 TH/s (USD)</span>                             
                                    <b-form-input :value="energy"  type="number" disabled />

                                </validation-provider>
                            </b-form-group>

                            <b-card-title style="margin-top: 1.53rem">
                                Партнерская программа
                            </b-card-title>

                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Прибыль партнера за контракт реферала периодом 12-23 месяца (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Прибыль партнера за контракт реферала периодом 12-23 месяца (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitation_percent_12_23"  />

                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Прибыль партнера за контракт реферала периодом 24-35 месяцев (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Прибыль партнера за контракт реферала периодом 24-35 месяцев (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitation_percent_24_35"  />

                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Прибыль партнера за контракт реферала периодом 36 месяцев (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Прибыль партнера за контракт реферала периодом 36 месяцев (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitation_percent_36"  />

                                </validation-provider>
                            </b-form-group>

                              <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Прибыль партнера за покупку оборудования рефералом (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Прибыль партнера за покупку оборудования рефералом (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitation_percent_m"  />

                                </validation-provider>
                            </b-form-group>

                              <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Бонус рефералу на первую аренду мощности (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Бонус рефералу на первую аренду мощности (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitee_percent"  />

                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="form-group-with-label">
                                <validation-provider
                                        #default="{ errors }"
                                        name="Скидка рефералу на первую покупку оборудования (%)"
                                    >
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Скидка рефералу на первую покупку оборудования (%)</span>                             
                                    <b-form-input  placeholder="" type="number" v-model="form.ref_invitee_percent_m"  />

                                </validation-provider>
                            </b-form-group>

                            
                            <div class="d-flex justify-content-end">
                                <b-button @click="save" variant="primary">
                                    Сохранить
                                </b-button>
                            </div>
                        </form>
                     </validation-observer>     
                                       
                </b-col>
                <b-col md="6">
                    <b-card-title>
                       Интеграции
                    </b-card-title>
               

                      <div class="d-flex justify-content-between align-items-center">

                        <span> AmoCRM   
                            <b-badge
                                v-if="integrations.amocrm"
                                pill
                                
                                class="mr-1 ml-auto"
                                >
                                    работает
                            </b-badge>
                            <b-badge
                                v-else
                                pill
                                variant="danger"
                                class="mr-1 ml-auto"
                                >
                                    не работает
                            </b-badge>
                        
                        </span>
                            
                        <b-button v-if="!integrations.amocrm" @click="connectWithAmoCRM" variant="primary">
                            Подключить AmoCRM
                        </b-button>

                        <b-button  v-confirm="{
                                            loader: true,
                                            ok: dialog => deleteAmoCRM(dialog),
                                            message: {
                                                title: 'Удаление интеграции AmoCRM',
                                                body: 'Вы действительно хотите удалить интеграцию?',
                                            },
                                        }" v-else variant="danger">
                            Удалить AmoCRM
                        </b-button>

                    </div>
                    
                </b-col>
            </b-row>
        </b-card-body>
        
    </b-card>
    
</template>

<script>

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import contracts from "@/modules/user/contracts"
    
    
    export default {

        data() {
            return {
                required,
                form: {
                    thsp: contracts.values.P,
                    kvth: contracts.values.K,
                    wpt: contracts.values.W,
                    ref_invitee_percent_m: contracts.values.REFINVITEEM,
                    ref_invitee_percent: contracts.values.REFINVITEE,

                    ref_invitation_percent_12_23: contracts.values.REFINVITATION1223,
                    ref_invitation_percent_24_35: contracts.values.REFINVITATION2435,
                    ref_invitation_percent_36: contracts.values.REFINVITATION36,
                    ref_invitation_percent_m: contracts.values.REFINVITATIONM
                },

                integrations : {
                    amocrm: false
                }
            }
        },
        methods: {  
            
            connectWithAmoCRM( args ) {
                
                let popup = window.open('https://www.amocrm.ru/oauth?client_id=ab899744-537a-4200-a299-f55d7f8ecfa4&state=1&mode=post_message', 'Подключение amoCRM', 'scrollbars, status, resizable, width=750, height=580');
                
                window.addEventListener('message',  (e) => {
                    
                  if (e.data.error !== undefined) {
                    console.log('Ошибка - ' + e.data.error)
                  } else {
                    console.log('Авторизация прошла')
                  }

                  popup.close();
                  
                });

                
            },
            
            
            save( args ) {
                 this.$refs.settings.validate().then(success => {
                     
                    if(!success) return;
                     
                    this.$request.post("app/updatePrices", this.params ).then( rsp => {
                         
                        contracts.values.P = this.form.thsp;
                        contracts.values.K = this.form.kvth;
                        contracts.values.W = this.form.wpt;
                        
                        contracts.values.REFINVITEE = this.form.ref_invitee_percent;
                        contracts.values.REFINVITEEM = this.form.ref_invitee_percent_m;

                        contracts.values.REFINVITATION1223 = this.form.ref_invitation_percent_12_23;
                        contracts.values.REFINVITATION2435 = this.form.ref_invitation_percent_24_35;
                        contracts.values.REFINVITATION36 = this.form.ref_invitation_percent_36;
                        contracts.values.REFINVITATIONM = this.form.ref_invitation_percent_m;
                         
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Готово`,
                              text: `Настройки успешно обновлены`,
                              icon: 'CheckCircleIcon',
                              variant: 'success',
                            }
                        });
                    });
                     
                 });
            },

            getIntegrations() {
                this.$request.get("integrations").then( result => {

                    result.items.forEach(element => {
                        this.integrations[element.platform] = true
                    });
                });     
            },

            deleteAmoCRM(dialog) {

                this.$request.post("integrations/remove", {'remove': 'amocrm'}).then( result => {
                   this.integrations.amocrm = false
                });  

                dialog.close();

            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        computed: {
            params() {
                
                let params = {
                    'egp' : this.energy
                };
                
                Object.keys( this.form ).forEach(key => {
                    params[key] = this.form[key];
                });
                
                return params;                
            },
            energy() {
                return (this.form.kvth * (this.form.wpt / 1000)).toFixed(6);
            }
        },
        watch: {

        },
        mounted() {
            this.getIntegrations();
        },

    }

</script>